<template>
    <div class="w3-container" style="padding-top: 40px;padding-bottom:16px; margin-top:40px" v-bind:style="'height:'+  (screenHeight-40) +'px;'">
        <Toolbar  
            :click-add="showAdd"
            :click-edit="showEdit"
            :click-delete="showDelete"
            :click-refresh="showRefresh"
            :set-filter="loadDataSearch"
            :status-print="false"
            :data   = "recs"
            :fields = "json_fields"
            type    = "xls"
            name    = "Customer.xls"
        />
        <div class="w3-container" style="padding: 0px;">
            <div id="wrapper">
                <div id="content">
                    <div class="w3-hide-small w3-hide-medium" id="boundary">
                        <table id="header" cellpadding="4" cellspacing="0" border="0" style="width:100%;">
                            <thead>
                                <tr class="w3-theme-l2">
                                    <th width="60px">No. </th>
                                    <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="">{{fl.name}}</th>
                                    <th width="1px"></th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-165) +'px;'">
                        <table class="w3-table w3-table-all">
                            <tr v-for="(dt, index) in recs" :key="index" class="w3-hover-pale-blue w3-small cursor" v-on:dblclick="showEdit(dt, index)" v-on:click="setKlikRow(index, dt)" :class="index == selectedRow ?'w3-pale-blue':''">
                                <td class="w3-hide-large" v-on:click="setKlikRow(index, dt)">
                                    <div class="w3-col s4 m2 w3-theme-l5">
                                        <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>Customer
                                    </div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.tc_name}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Alamat</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.tc_address}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Phone</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.tc_phone}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Lokasi</div>
                                    <div class="w3-col s8 m10 w3-theme-l4" v-html="setLokasi(dt.tc_lokasi)">
                                    </div>
                                </td>
                                <td width="60px" class="w3-hide-small w3-hide-medium w3-border-right" style="text-align:center">
                                    <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                                </td>
                                <td :width="findObjFind('width','tc_id')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    <span v-html="setTextFind(dt.tc_id,'tc_id')"></span>
                                </td>
                                <td :width="findObjFind('width','tc_name')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    <span v-html="setTextFind(dt.tc_name,'tc_name')"></span>
                                </td>
                                <td :width="findObjFind('width','tc_address')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    <span v-html="setTextFind(dt.tc_address,'tc_address')"></span>
                                </td>
                                <td :width="findObjFind('width','tc_phone')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    <span v-html="setTextFind(dt.tc_phone,'tc_phone')"></span>
                                </td>
                                <td :width="findObjFind('width','tc_lat')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    {{dt.tc_lat}}/{{dt.tc_lng}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" style="padding: 3px 0px 0px 10px;" :style="'width:' + findObjFind('width','tc_lokasi')" class="w3-hide-small w3-hide-medium">
                                    <span v-html="setLokasi(dt.tc_lokasi)"></span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- pagging -->
        <div style="height: 35px;bottom: 25px;">
            <div class="w3-bar w3-center w3-theme" style="height: 38px;position: absolute;bottom: 0;left: 0px;">
                <a :class="pages.halaman == 1 ?'dsb':''" class="w3-bar-item w3-button" @click="first()" href="#">First</a>
                <a :class="pages.halaman == 1 ?'dsb':''" class="w3-bar-item w3-button" @click="prev()" href="#">«</a>
                <a class="w3-bar-item w3-button w3-theme-l4" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a>
                <a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="w3-bar-item w3-button" @click="next()" href="#">»</a>
                <a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="w3-bar-item w3-button" @click="last()" href="#">Last</a>
                <a class="w3-bar-item w3-button w3-border-left w3-right" href="#">Rec {{pages.ttlrec}}</a>
            </div>
        </div>
        <div id="modal-form" class="w3-modal scrl-y">
            <div class="w3-modal-content w3-animate-zoom w3-card-4 w3-round-large" style="max-width:600px;top: -40px;">
            <header class="w3-container w3-theme-d3 round-top-large"> 
                <span onclick="document.getElementById('modal-form').style.display='none'" 
                class="w3-button w3-display-topright w3-round-large">&times;</span>
                <h5><i class="fa fa-windows"></i>&nbsp; Add / Edit data</h5>
            </header>
            <div class="w3-container">
                <form class="w3-container" id="frmCust" v-on:submit.prevent="saveData" autocomplete="off">
                    <div class="w3-row">
                        <div class="w3-row">
                            <div class="w3-col l12 w3-padding" id="div-map">
                                <l-map class="w3-round-large" style="height: 30vh;" 
                                    :zoom="map.currentZoom" 
                                    :center="map.center" 
                                    @update:center="centerUpdate"
                                    @click="setCenterMarker"
                                    ref="mymap">
                                    <l-tile-layer :url="map.url" :attribution="map.attribution"></l-tile-layer>
                                    <l-marker :lat-lng="map.markerLatLng"></l-marker>
                                </l-map>
                            </div>
                        </div>
                        <div class="w3-row">
                            <div class="w3-col l6 w3-padding">
                                <label>Latitude</label>
                                <input class="w3-input w3-small w3-round-large" type="text" v-model="rec.tc_lat" v-on:input="setMap()" placeholder="" required>
                            </div>
                            <div class="w3-col l6 w3-padding">
                                <label>Longitude</label>
                                <input class="w3-input w3-small w3-round-large" type="text" v-model="rec.tc_lng" v-on:input="setMap()" placeholder="" required>
                            </div>
                        </div>
                        <div class="w3-row">
                            <div class="w3-col l6 w3-padding">
                                <label>Nama</label>
                                <input class="w3-input w3-small w3-round-large" type="text" v-model="rec.tc_name" placeholder="" required>
                            </div>
                            <div class="w3-col l6 w3-padding">
                                <label>Phone</label>
                                <input class="w3-input w3-small w3-round-large" type="text" v-model="rec.tc_phone" placeholder="" required>
                            </div>
                        </div>
                        <div class="w3-row">
                            <div class="w3-col l3 w3-padding">
                                <label>Lokasi</label>
                                <select v-model="rec.tc_lokasi" class="w3-select w3-round-large w3-white" style="width: 100%;" required>
                                    <option value="" disabled selected>Pilih Lokasi</option>
                                    <option value="S">Surabaya</option>
                                    <option value="M">Semarang</option>
                                </select>
                            </div>
                            <div class="w3-col l9 w3-padding">
                                <label>Alamat</label>
                                <input class="w3-input w3-small w3-round-large" type="text" v-model="rec.tc_address" placeholder="" required>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <footer class="w3-container w3-theme-d1">
                <h6 class="w3-right">
                <button form="frmCust" class="w3-small w3-btn w3-ripple w3-white w3-round" type="submit"><i class="fa fa-save"></i> Save</button>
                <button type="button" class="w3-small w3-btn w3-ripple w3-red w3-round" onclick="document.getElementById('modal-form').style.display='none'"><i class="fa fa-close"></i> Close</button>
                </h6>
            </footer>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import {
  LMap,
  LTileLayer,
  LMarker,
  LControl,
  LCircle,
  LTooltip
} from "vue2-leaflet";
import Config from '@/conf/Config.js';
import { Icon } from "leaflet";
import axios from "axios";
import 'leaflet/dist/leaflet.css';
import { ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
import Toolbar from "@/components/Toolbar.vue";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Customer',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LControl,
        LCircle,
        LTooltip,
        ModelListSelect,
        Toolbar
    },
    data() {
        return {
            screenHeight : 0,
            selectedRow : -1,
            map : {
                center: [
                    -7.240889, 112.693945
                ],
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                currentZoom: 15,
                markerLatLng: [
                    -7.240889, 112.693945
                ],
                attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            },
            rec: {},
            recs: [],
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:''},
            filters : {
            	operator : 'AND',
                fields :[
                        {fieldFind :'', key : 'tc_id', type: 'text', name : 'ID', filter : true, width : '100px', grid : true},
                        {fieldFind :'', key : 'tc_name', type: 'text', name : 'Name', filter : true, width : '200px', grid : true},
                        {fieldFind :'', key : 'tc_address', type: 'text', name : 'Address', filter : true, width : '', grid : true},
                        {fieldFind :'', key : 'tc_phone', type: 'text', name : 'Phone', filter : true, width : '100px', grid : true},
                        {fieldFind :'', key : 'tc_lat', type: 'text', name : 'lat/lng', filter : true, width : '140px', grid : true},
                        {fieldFind :'', key : 'tc_lng', type: 'text', name : 'lng', filter : true, width : '', grid : false},
                        {fieldFind :'', key : 'tc_lokasi', type: 'select', name : 'Lokasi', filter : true, width : '80px', grid : true, valueFind: [{key:'S',label:'Surabaya'},{key:'M',label:'Semarang'}]},
                ],
                find : []   
            },
            customers: [],
            /**Untuk transfer excel*/
            json_fields : {
                'ID' : 'tc_id',
                'NAMA' : 'tc_name',
                'ALAMAT' : 'tc_address',
                'PHONE' : 'tc_phone',
                'LAT' : 'tc_lat',
                'LNG' : 'tc_lng'
            },
        }
    },
    methods : {
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        setTextFind(txt,field){
            var fieldvalue = "";
            var ketemu = false;
            var self = this;
            if(self.filters.find!==undefined )
                if ( self.filters.find.length >0 ) {
                    self.filters.find.map(function(value){
                        if(value.fields ===field){
                            ketemu = true;
                        }
                        if(fieldvalue!=='null')
                            fieldvalue += ((fieldvalue!=='')?';':'')+ value.fieldValue ;
                        else
                            fieldvalue += ((fieldvalue!=='null')?';':'')+ value.fieldValue ;
                    });
                }
            if(ketemu){
                return self.sethighlight(txt,fieldvalue);
            }else{
                return txt;
            }
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dt);
        },
        loadData(){
            this.screenHeight = window.innerHeight;
            let self = this;
            self.selectedRow = -1;
            self.rec = {};
            let params = {
                pfunction:'setcustomerpg',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search
            }
            axios.post("master/ApiCustomer.php",params).then(function (response) {
                let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
                self.pages.ttlrec = parseFloat(response.data.ttlrec);
                self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
                response.data.rec.map(function(val){
                    val['idx'] = idx;
                    idx++;
                });
                self.recs = response.data.rec;
            });
        },
        showRefresh(){
            this.pages.search = "";
            this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        async showAdd() {
            let self = this;
            self.stsAdd = true;
            let key = await axios.post("admin/ApiGetId.php",{
                pfunction : 'getId',
                vc_prefix : 'CUS',
                vc_table : 'tb_customer'
            });
            self.rec = {
                tc_id : key.data,
                pfunction : 'addcustomer',
                tc_name : '',
                tc_address : '',
                tc_phone : '',
                tc_lat : -7.240889,
                tc_lng : 112.693945
            };
            setTimeout(() => {
                this.$refs.mymap.mapObject.invalidateSize(); 
            }, 500);
            document.getElementById("modal-form").style.display = "block";
        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih customer.!');
                return false;
            }else{
                this.stsAdd = false;
                setTimeout(() => {
                    this.$refs.mymap.mapObject.invalidateSize(); 
                    this.setMap();
                }, 500);
                document.getElementById('modal-form').style.display='block';
            }
        },
        saveData(event){
            var self = this;
            if (self.stsAdd == true) {
                self.rec.pfunction = "add";
            }else{
                self.rec.pfunction = "edit";
            }
            self.rec.userentry = localStorage.username;
            axios.post("master/ApiCustomer.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    if (self.stsAdd) {
                        self.recs.splice(0, 0, self.rec);
                    }else{
                        self.recs[self.selectedRow] = self.rec;
                    }
                    document.getElementById('modal-form').style.display='none';
                    self.selectedRow = -1;
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        showDelete() {
            var self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih customer.!');
                return false;
            }else{
                swal(
                    {
                        title: "Yakin Ingin Menghapus Data ..!",
                        text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Ya, Hapus Data",
                        cancelButtonText: "Tidak, Batal Hapus",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                    },
                    function(isConfirm) {
                        if (isConfirm) {
                            var vparams = { 
                                tc_id : self.rec.tc_id,
                                pfunction : "delete"
                            };
                            axios.post("master/ApiCustomer.php",vparams).then(function (response) {
                                if (response.data.success == true) {
                                    // self.$toast.success(response.data.msg);
                                    // self.$delete(self.recs, self.selectedRow);
                                    swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                                    self.$delete(self.recs, self.selectedRow);
                                }else{
                                    // self.$toast.error(response.data.msg);
                                    swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                                }
                            });
                        }
                    }
                );
            }
        },
        centerUpdate(center) {
            this.map.markerLatLng = center;
            this.rec.tc_lat = Number(center['lat']).toFixed(6);
            this.rec.tc_lng = Number(center['lng']).toFixed(6);
        },
        setCenterMarker(e){
            this.map.markerLatLng = e.latlng;
            this.map.center = e.latlng;
        },
        setMap(){
            this.map.markerLatLng = {
                lat : this.rec.tc_lat,
                lng : this.rec.tc_lng
            };
            this.map.center = {
                lat : this.rec.tc_lat,
                lng : this.rec.tc_lng
            };
        },
        moveCenter(){
            // console.log(e.target.options);
            // console.log(this.$refs.mymap.mapObject); 
            // self.customers = response.data.rec;
        },
        setLokasi(dt) {
            if (dt === "S") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Surabaya</div>";
            } else {
                return "<div class='w3-orange w3-center w3-round' style='width:55px;margin-top:5px;'>Semarang<div>";
            }
        },
        setInit() {
            // let self = this;
            // axios.post("master/ApiCustomer.php",{
            //     pfunction : 'setcustomer'
            // }).then(function (response) {
            // });
        }

    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;                
            });
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
        this.loadData();
    }
};
</script>
